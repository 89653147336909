import React from "react";
import { Link } from "gatsby"
import HomeLg from "../fragments/Home/HomeLg";
import Layout from "../fragments/layout/Layout";
import homeImg from '../images/home-rd.jpg'

const Page404 = () => {
  return (
    <Layout>
      <h3>Lo sentimos, no hemos encontrado lo que estaba buscando</h3>
      <div style={{ width: "100%", height:"75vh", overflow: "hidden", marginTop: 20}}>
        <HomeLg />
       <Link to="/" className="small404"><img src={homeImg} alt="Home" /></Link>
      </div>
    </Layout>
  )
}

export default Page404
