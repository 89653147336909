import React from 'react'
import { Link } from 'gatsby'
import './HomeLg.scss'

const HomeLg = () => {
  return (
    <div className="home-container-lg">
      <div className="home-lg">
        <Link to="/estudio" className="home-menu-item">
          <h1>estudio</h1>
        </Link>
        <Link to="/proyectos" className="home-menu-item">
          <h1>proyectos</h1>
        </Link>
        <Link to="/reportajes" className="home-menu-item">
          <h1>reportajes</h1>
        </Link>
      </div>
    </div>
  )
}

export default HomeLg
